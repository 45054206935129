import { api, currentOrganization } from '../api'

const RegistrationRepository = {
    index (params = {}) {
        return api().$get(`organization/${currentOrganization().uuid}/registrations`, params)
    },

    export (params = {}) {
        return api().$get(`/organization/${currentOrganization().uuid}/registrations/export`, params, {
            responseType: 'blob'
        })
    },

    show (registrationUuid, params = {}) {
        return api().$get(`organization/${currentOrganization().uuid}/registrations/${registrationUuid}`, params)
    },

    update (registrationUuid, payload) {
        return api().$put(`organization/${currentOrganization().uuid}/registrations/${registrationUuid}`, payload)
    },

    delete (registrationUuid) {
        return api().$delete(`organization/${currentOrganization().uuid}/registrations/${registrationUuid}`)
    },

    detachCreche (registrationUuid) {
        return api().$post(`organization/${currentOrganization().uuid}/registrations/${registrationUuid}/creche/detach`)
    },

    clone (registrationUuid) {
        return api().$post(`organization/${currentOrganization().uuid}/registrations/${registrationUuid}/clone`)
    }
}

export default RegistrationRepository
